<template>
    <div class="flex justify-center items-center flex-wrap">
        <div class="max-w-screen-lg w-full relative" style="min-height:75vh">
            <div class="px-4 pb-8 md:py-36">
                <div class="absolute top-0 left-0 p-2">
                    <a class="text-3xl" @click="back()">
                        <i class="jam jam-chevron-left"></i>
                    </a>
                </div>

                <div class="flex flex-wrap justify-center">
                    <div class="w-full text-center" style="height: 20rem;">
                        <lottie-animation path="lottie/exame.json" :loop='false' style="width: 20rem;"></lottie-animation>
                    </div>
                    <section class="mx-auto w-full md:w-1/3">
                        <h1 class="text-2xl text-gray-700 font-semibold text-center">Qual tipo de exame você deseja agendar?</h1>
                        <div class="p-2 w-full">
                            <b-button class="w-full" size='is-medium' type="is-primary" rounded @click="$router.push({name: 'agendarExamesLaboratoriais'})">
                                <div class="text-xl">
                                    <span class="jam jam-flask"></span>
                                    Exames Laboratoriais
                                </div>
                            </b-button>
                        </div>
                        <div class="p-2 w-full">
                            <b-button class="w-full bg-red-600" style="color: #FFF" size='is-medium' rounded @click="$router.push({name: 'agendarOutrosExames'})">
                                <div class="text-xl">
                                    <span class="jam jam-activity"></span>
                                    Exames de Imagem
                                </div> 
                            </b-button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
    name: 'Exames',
    components: { LottieAnimation },
    methods: {
        back() {
            this.$router.push({name:"home"})
        },
    }
}
</script>